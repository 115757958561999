import Header from "@/Components/Header";
import Banner from "@/Components/Banner";
import image from "./hotel.jpg"
import {Alert, Button, DatePicker, Form, Input, message, Modal} from "antd";

import moment, {Moment} from "moment"
import "./index.scss"

import logo1 from "./logo/hotel_bw.png"
import logo2 from "./logo/hotel_glyt.png"
import logo3 from "./logo/hotel_ihg.png"
import logo4 from "./logo/hotel_jl.png"
import logo5 from "./logo/hotel_jzsj.png"
import logo6 from "./logo/hotel_ky.png"
import logo7 from "./logo/hotel_wdm.png"
import logo8 from "./logo/hotel_wh.png"
import logo9 from "./logo/hotel_xdw.png"
import logo10 from "./logo/hotel_xed.png"
import logo11 from "./logo/hotel_xgll.png"
import logo12 from "./logo/hotel_yg.png"
import Service from "@/Components/Service";
import {useNavigate} from "react-router-dom";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useRequest} from "@/Request";
import {getIndexData, getSearchList} from "@/Request/api";
import * as _ from "lodash"

const disabledPicker = (current: Moment) => {
  return current.isBefore(moment().subtract(1, "d"))
}

const Home = function () {
  const navigate = useNavigate()
  const [date, setDate] = useState<any>([])
  const [tempDate, setTempDate] = useState<any>([null, null])
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState("")
  const [nowSubmit, setNowSubmit] = useState(false)
  const [searchList, setSearchList] = useState<string[]>([])
  const key = useRef("")

  const debounceDispatchSearchList = useCallback(_.debounce((str: string) => {
    key.current = str
    dispatchGetSearchList(str)
  }), [])

  const [searchListVisible, setSearchListVisible] = useState(false)

  const {action: dispatchGetSearchList} = useRequest(getSearchList, {
    onSuccess(res) {
      if (res.keyword === key.current) {
        setSearchListVisible(true)
        setSearchList(res.list.map(item => {
          return item.name
        }))
      }
    }
  })

  function submit(date: any, value: string) {
    navigate("/list", {
      state: {
        value: value,
        date: [
          (date[0] as Moment).format(),
          (date[1] as Moment).format()
        ]
      }
    })
  }

  const {loading, data} = useRequest(getIndexData, {
    auto: true
  })
  function isMobile() {
    return (/Mobi|Android|iPhone/i.test(navigator.userAgent))
  }

  return (
    <div id="home">
      <Header white={true} position={true}/>
      <Banner className="banner" image={image} opacity={false}>
        <div className="search-container z-width">
          <div className="title">Find a hotel</div>
          <div className="search-border">
            <div className="search">
              <input autoComplete="new-password" value={value} onChange={e => {
                debounceDispatchSearchList(e.target.value)
                setSearchListVisible(false)
                setValue(e.target.value)
              }} type="text" placeholder="Please enter the city name or hotel name separated by spaces"/>
              <div className="date" onClick={() => {
                setVisible(true)
              }}>
                {!date.length ? ("Select Date") : (
                  <>
                    <p><span>In:</span> {date[0].format("YY-MM-DD")}</p>
                    <p><span>Out:</span> {date[1].format("YY-MM-DD")}</p>
                  </>
                )}
              </div>
              {
                searchListVisible && (
                  <div className="search-list">
                    {
                      searchList.map((item, key) => {
                        return (
                          <div className="search-item" key={key} onClick={() => {
                            setValue(item)
                            setNowSubmit(true)
                            setVisible(true)
                          }}>
                            { item }
                          </div>
                        )
                      })
                    }
                  </div>
                )
              }
              <button className="submit" onClick={() => {
                let ok = !!date[0] && !!date[1]
                if (value.length === 0) {
                  return
                } else if (!ok) {
                  setNowSubmit(true)
                  setVisible(true)
                } else {
                  submit(date, value)
                }
              }}>Search</button>
            </div>
          </div>
          <Modal
            className="select-modal"
            title="select date"
            visible={visible}
            onCancel={() => {
              setVisible(false)
              setNowSubmit(false)
            }}
            onOk={() => {
              setVisible(false)
              if (!tempDate[0] || !tempDate[1]) {
                message.warning("Please select a date")
                return
              }
              if (tempDate.length) {
                setDate(tempDate)
              }
              if (nowSubmit) {
                submit(tempDate, value)
              }
            }}
          >
            {
              isMobile() ? (
                <div>
                  <Alert style={{marginBottom: 15}} message="Please ignore the hour, minute and second at the end"/>
                  <DatePicker.RangePicker
                    showTime={{
                      defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')]
                    }}
                    disabledDate={disabledPicker}
                    value={tempDate}
                    style={{width: "100%"}}
                    onChange={value => {
                      setTempDate(value)
                    }}
                  />
                </div>
              ) : (
                <DatePicker.RangePicker
                  value={tempDate}
                  onChange={value => {
                    setTempDate(value)
                  }}
                  style={{width: "100%"}}
                  disabledDate={disabledPicker}
                />
              )
            }
          </Modal>
        </div>
      </Banner>
      <div className="box1">
        <Service className="z-width"/>
      </div>
      <div className="box2">
        <div className="background">
          <div className="shadow"></div>
          <div className="img"></div>
        </div>
        <div className="title">Spend less</div>
        <div className="subtitle">Competitive ultra-low house price</div>
        <div className="list z-width">
          {
            data?.hotel.map(item => {
              return (
                <div key={item.id} className="item" onClick={() => {
                  navigate("/list", {
                    state: {
                      value: item.name,
                      date: [
                        moment().format(),
                        moment().add(1, "d").format()
                      ]
                    }
                  })
                }}>
                  <img src={item.p_image} alt=""/>
                  <div className="right">
                    <div className="title">{item.name}</div>
                    <div className="price">$ <span>{item.price}</span></div>
                    <div className="position">{item.position}</div>
                    <div className="more">View details</div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="box3">
        <div className="title">Better to live</div>
        <div className="subtitle">Select world famous hotels</div>
        <div className="list z-width">
          <a className="item" href="#">
            <img src={logo1} alt=""/>
          </a>
          <a className="item" href="#">
            <img src={logo2} alt=""/>
          </a>
          <a className="item" href="#">
            <img src={logo3} alt=""/>
          </a>
          <a className="item" href="#">
            <img src={logo4} alt=""/>
          </a>
          <a className="item" href="#">
            <img src={logo5} alt=""/>
          </a>
          <a className="item" href="#">
            <img src={logo6} alt=""/>
          </a>
          <a className="item" href="#">
            <img src={logo7} alt=""/>
          </a>
          <a className="item" href="#">
            <img src={logo8} alt=""/>
          </a>
          <a className="item" href="#">
            <img src={logo9} alt=""/>
          </a>
          <a className="item" href="#">
            <img src={logo10} alt=""/>
          </a>
          <a className="item" href="#">
            <img src={logo11} alt=""/>
          </a>
          <a className="item" href="#">
            <img src={logo12} alt=""/>
          </a>
        </div>
      </div>
    </div>
  )
}
export default Home