import Banner from "../../Components/Banner";
import Header from "@/Components/Header";
import "./index.scss"
import {DatePicker, Form, Input, message, Radio, Spin} from "antd";
import mail from "./img/mail.png"
import phone from "./img/phone.png"
import position from "./img/position.png"
import {useRequest} from "@/Request";
import {postContact} from "@/Request/api";
import Store from "@/Store";
import {observer} from "mobx-react-lite";
import iconBdp from "./img/icon-pdp.svg"
import iconAffirm from "./img/icon-affirm.svg"
import iconSupport from "./img/icon-support.svg"
import iconCryptocurrency from "./img/icon-cryptocurrency.svg"
import budgetTravel from "./img/budget_travel.svg"
import cnn from "./img/cnn.svg"
import fox from "./img/fox.svg"
import latimes from "./img/latimes.svg"
import nytFrom from "./img/nyt.svg"
import usaToday from "./img/usa-today.svg"
import wsj from "./img/wsj.svg"

const box2LogoList = [
  budgetTravel,
  cnn,
  fox,
  latimes,
  nytFrom,
  usaToday,
  wsj
]

const Contact = function () {
  const [form] = Form.useForm()
  const {action, loading} = useRequest(postContact, {
    onSuccess(res) {
      message.success(res)
      form.resetFields()
    }
  })
  return (
    <div id="contact">
      <Header/>
      <Banner title="Contact" subtitle="We're here to help!"/>
      <div className="email">
        <div className="left">
          <Spin spinning={loading}>
            <div className="title">What can we help you with?</div>
            <Form size="large"  form={form} onFinish={values => {
              action(values)
            }}>
              <Form.Item label="Name" name="name" rules={[
                {required: true}
              ]}>
                <Input placeholder="full name"/>
              </Form.Item>
              <Form.Item label="Email" name="email" rules={[
                {required: true}
              ]}>
                <Input placeholder="email address"/>
              </Form.Item>
              <Form.Item label="Phone" name="phone">
                <Input/>
              </Form.Item>
              <Form.Item label="Type" name="type" initialValue="One way">
                <Radio.Group>
                  <Radio value="One way">One way</Radio>
                  <Radio value="Round Trip">Round Trip</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item noStyle>
                <div className="flex">
                  <Form.Item label="From" name="from" rules={[{required: true}]}>
                    <Input/>
                  </Form.Item>
                  <Form.Item label="To" name="to" rules={[{required: true}]}>
                    <Input/>
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item noStyle dependencies={["type"]}>
                {({getFieldValue}) => {
                  return (
                    <>
                    {
                      getFieldValue("type") === "One way" ? (
                        <Form.Item rules={[{required: true}]} label="Date" name="date">
                          <DatePicker/>
                        </Form.Item>
                      ) : (
                        <Form.Item rules={[{required: true}]} label="Date" name="dates">
                          <DatePicker.RangePicker/>
                        </Form.Item>
                      )
                    }
                    </>
                  )
                }}
              </Form.Item>
              <Form.Item label="Message" name="message">
                <Input.TextArea rows={4} placeholder="Please enter the number of passengers and whether they are adults. If not, it will be deemed that there is only one adult passenger by default"/>
              </Form.Item>
            </Form>
            <div className="center">
              <button onClick={form.submit}>SEND</button>
            </div>
          </Spin>
        </div>
        <div className="right">
          <div className="top">
            <div className="item">
              <div className="left">
                <img src={position} alt=""/>
              </div>
              <div className="text" dangerouslySetInnerHTML={{__html:Store.setting.contact_position}}></div>
            </div>
            <div className="item">
              <div className="left">
                <img src={phone} alt=""/>
              </div>
              <div className="text" dangerouslySetInnerHTML={{__html:Store.setting.contact_phone}}></div>
            </div>
            <div className="item">
              <div className="left">
                <img src={mail} alt=""/>
              </div>
              <div className="text" dangerouslySetInnerHTML={{__html:Store.setting.contact_email}}></div>
            </div>
          </div>
          <div className="faq">
            <div className="title">{Store.setting.contact_faq_title}</div>
            <div className="content">{Store.setting.contact_faq_text}</div>
            <button onClick={() => {
              window.open(Store.setting.contact_faq_url)
            }}>{Store.setting.contact_faq_btn}</button>
          </div>
        </div>
      </div>
      <div className="bottom-info">
        <div className="box">
          <div className="title">{Store.setting.contact_b_title}</div>
          <div className="content" dangerouslySetInnerHTML={{__html:Store.setting.contact_b_text}}>
          </div>
        </div>
      </div>
      <div className="box1">
        <div className="z-width">
          <div className="top">
            <div className="left">
              CheapAir.com<br/>
              <span>Raises the Bar</span>
            </div>
            <div className="right">
              Trusted by the most discerning travelers, CheapAir.com is the ultimate search platform for flights, hotels, and cars.
            </div>
          </div>
          <div className="content">
            <div className="item">
              <img src={iconBdp} alt=""/>
              <p className="title">UP TO $100 BACK</p>
              <div className="text">If fares drop after your purchase, we’ll credit you the difference.</div>
            </div>
            <div className="item">
              <img src={iconAffirm} alt=""/>
              <p className="title">0% FINANCING</p>
              <div className="text">0% FINANCING
                Fly Now Pay Later with 3, 6 and 12-month financing on flights and hotels. *For qualified customers for up to 3 months details here</div>
            </div>
            <div className="item">
              <img src={iconSupport} alt=""/>
              <p className="title">U.S. BASED TRAVEL ADVISORS</p>
              <div className="text">You can reach our highly-rated travel advisors with every booking.</div>
            </div>
            <div className="item">
              <img src={iconCryptocurrency} alt=""/>
              <p className="title">CRYPTOCURRENCY PAYMENTS</p>
              <div className="text">Travel with crypto. Buy flights and hotels with Bitcoin, and more!</div>
            </div>
          </div>
        </div>
      </div>
      <div className="box2">
        <div className="z-width">
          <div className="title"><span>Loved By Travelers</span> and <span>Featured In</span></div>
          <div className="list">
            {
              box2LogoList.map((item, key) => {
                return <img key={key} src={item}/>
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Contact)