import React from 'react';
import Header from "./Components/Header";
import {Route, Routes} from "react-router-dom";
import Contact from "./Pages/Contact";
import "@/assets/style/common.scss"
import Home from "@/Pages/Home";
import List from "@/Pages/List";
import {EmailLogin, Login, Register} from "@/Pages/Login";
import User from "@/Pages/User";
import Footer from "@/Components/Footer";

function App() {
  return (
    <>
      <Routes>
        <Route element={<Home/>} path="/"/>
        <Route element={<List/>} path="list"/>
        <Route element={<Contact/>} path="contact"/>
        <Route element={<Login/>} path="login"/>
        <Route element={<Register/>} path="register"/>
        <Route element={<EmailLogin/>} path="email_login"/>
        <Route element={<User/>} path="user/*"/>
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
