import Header from "@/Components/Header";
import Banner from "@/Components/Banner";
import image from "../Home/image.jpg"
import "./index.scss"
import {Alert, Button, DatePicker, Divider, Form, Input, Modal, Select, Spin} from "antd";
import Service from "@/Components/Service";
import Hotel from "@/Components/Hotel";
import {RefObject, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import moment, {Moment} from "moment";
import {useRequest} from "@/Request";
import {getBrand, getHotels, HotelRaw, postOrder} from "@/Request/api";
import {useEventListener} from "ahooks"
import Pay, {PayRef} from "@/Components/Pay";
import MakeWork, {MakeWorkRef} from "@/Components/MakeWork";


const List = function () {
  const [form] = Form.useForm()
  const [page, setPage] = useState(1)
  const location = useLocation()
  const [disabled, setDisabled] = useState(false)
  const [list, setList] = useState<HotelRaw[]>([])
  const [count, setCount] = useState(0)
  const [dates, setDates] = useState<Moment[]>([])
  const {data: brand, loading: brandLoading} = useRequest(getBrand, {
    auto: true
  })

  function isMobile() {
    return (/Mobi|Android|iPhone/i.test(navigator.userAgent))
  }

  useEventListener("scroll", function () {
    let scrollTop = document.body.scrollTop || document.documentElement.scrollTop
    if (document.body.scrollHeight <= (scrollTop + document.documentElement.clientHeight + 20) && !disabled && !loading) {
      refreshList(page + 1).then(res => {
        setList([...list, ...res.list])
      })
    }
  })

  useEffect(() => {
    if (location.state) {
      const state = location.state as {[key:string]: string}
      form.setFieldsValue({
        value: state.value,
        date: [
          moment(state.date[0]).hour(0).minute(0).second(0),
          moment(state.date[1]).hour(0).minute(0).second(0)
        ]
      })
    }
    refreshList(page).then(res => {
      setList(res.list)
    })
  }, [])

  const {loading, action} = useRequest(getHotels, {
    onSuccess(res) {
      if (res.list.length === 0) {
        setDisabled(true)
      }
      setCount(res.count)
    }
  })

  function getDate() {
    let values = form.getFieldsValue()
    if (!values.date) {
      values.date = [
        values.date1,
        values.date2
      ]
    }
    return values
  }

  function refreshList(page: number) {
    let values = getDate()
    setDates([...values.date])
    setDisabled(false)
    setPage(page)
    return action({
      ...values,
      page
    })
  }
  const [visible, setVisible] = useState(false)
  const [currentHotel, setCurrentHotel] = useState<HotelRaw | undefined>(undefined)

  function showModal(item: HotelRaw) {
    setCurrentHotel(item)
    setVisible(true)
  }

  const childRef = useRef<{
    validate?():Promise<any>
  }>({})


  const payRef = useRef<PayRef>()

  const {loading: postOrderLoading, action: dispatchPostOrder} = useRequest(postOrder, {
    onSuccess(order) {
      payRef.current?.open(order)
    }
  })

  const disabledPicker = (current: Moment, last?: Moment ) => {
    let t = true
    if (last) {
      t = last.isBefore(current)
    }
    return current.isBefore(moment().subtract(1, "d")) && t
  }

  const workRef = useRef<MakeWorkRef>()

  return (
    <div id="list">
      <Header/>
      <Banner className="banner" title="Hotel Find" image={image}/>
      <MakeWork onRef={workRef}/>
      <Spin spinning={brandLoading || loading}>
        <div className="z-width">
          <div className="head">
            <Form form={form} onFinish={() => {
              refreshList(1).then(res => {
                setList(res.list)
              })
            }}>
              <Form.Item noStyle>
                <div className="form-line-box top">
                  <div className="left">
                    <div className="line">
                      <Form.Item name="value" className="input-item" label="key word" rules={[
                        {required: true}
                      ]}>
                        <Input maxLength={300}/>
                      </Form.Item>
                      {
                        isMobile() ? (
                          <Form.Item name="date" className="range-date-item" label="Check in date">
                            <DatePicker.RangePicker
                              showTime={{
                                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')]
                              }}
                              disabledDate={disabledPicker}
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item name="date" className="range-date-item" label="Check in date">
                            <DatePicker.RangePicker disabledDate={disabledPicker}/>
                          </Form.Item>
                        )
                      }
                    </div>
                    <div className="line">
                      <Form.Item label="Hotel Group" name="brand" initialValue="all">
                        <Select>
                          <Select.Option value="all">All</Select.Option>
                          {brand && (
                            brand.map(item => {
                              return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                            })
                          )}
                        </Select>
                      </Form.Item>
                      <Form.Item label="Hotel price" name="price" initialValue="">
                        <Select>
                          <Select.Option value="">All</Select.Option>
                          <Select.Option value="0-500">0~500</Select.Option>
                          <Select.Option value="501-700">501~700</Select.Option>
                          <Select.Option value="701-900">701~900</Select.Option>
                          <Select.Option value="901-1200">701~900</Select.Option>
                          <Select.Option value="1200-99999">1200 +</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item label="sort order" name="sort" initialValue="default">
                        <Select>
                          <Select.Option value="default">default sorting</Select.Option>
                          <Select.Option value="acs">Price ASC</Select.Option>
                          <Select.Option value="desc">Price DESC</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <Button type="primary" htmlType="submit">查询</Button>
                </div>
              </Form.Item>
            </Form>
          </div>
          <div className="content">
            <div className="left">
              <div className="title">共 {count} 条搜索结果</div>
              <div className="list">
                {
                  list.map(item => {
                    return (
                      <Hotel
                        key={item.id}
                        data={{
                          image: item.p_image,
                          title: item.name,
                          position: item.position,
                          brand: item.brand?.name ?? "-"
                        }}
                        action={(
                          <>
                            <Button type="primary" onClick={() => {
                              showModal({
                                ...item
                              })
                            }}>Book now</Button>
                            <Button type="link" onClick={() => {
                              workRef.current?.open(`inquiry ${item.name}`, `[${item.name}] inquiry: \nCheck in date: \nCheck Out date: \nHello, I'd like to ask whether this hotel has a room in this time interval.`)
                            }}>inquiry</Button>
                          </>
                        )}
                        text={(
                          <>
                            <p>Form $<span>{item.price}</span></p>
                            <p>+ ServiceCharge <span>${item.real_service_charge}</span> / Day</p>
                          </>
                        )}
                      />
                    )
                  })
                }
              </div>
              {disabled && <div className="tips">No more.</div>}
            </div>
            <div className="right">
              <div className="title">Service</div>
              <Service/>
            </div>
          </div>
        </div>
      </Spin>
      <Pay onRef={payRef} close={() => {
        setVisible(false)
      }}/>
      <Modal
        maskClosable={false}
        confirmLoading={postOrderLoading}
        destroyOnClose={true}
        visible={visible}
        title="Confirm"
        onCancel={() => setVisible(false)}
        onOk={() => {
          childRef.current.validate?.().then((values) => {
            values.start_date = getDate().date[0].format()
            values.end_date = getDate().date[1].format()
            delete values.date
            dispatchPostOrder(values)
          })
        }}
      >
        <Info onRef={childRef} hotel={currentHotel} date={dates} setVisible={setVisible} visible={visible}/>
      </Modal>
    </div>
  )
}

type InfoProps = {
  hotel: HotelRaw | undefined
  date: Moment[]
  visible: boolean
  setVisible(v: boolean):void
  onRef: RefObject<{
    validate?():Promise<any>
  }>
}

function Info(props: InfoProps) {
  const [infoForm] = Form.useForm()
  const [price, setPrice] = useState(0)
  useEffect(() => {
    infoForm.setFieldsValue({
      date: [...props.date],
      hotel_id: props.hotel?.id
    })
    refreshPrice()
  }, [props.date])

  useImperativeHandle(props.onRef, () => ({
    validate() {
      return infoForm.validateFields()
    }
  }))

  function refreshPrice() {
    let values = infoForm.getFieldsValue()
    let days = values.date[1].diff(values.date[0], "days")
    let dayPrice = (Number(props.hotel?.price ?? 0) + Number(props.hotel?.real_service_charge ?? 0))
    setPrice(days * dayPrice)
  }

  return (
    <div className="buy-confirm">
      <Divider style={{marginTop: 0,marginBottom: 25}} orientation="left">Information registration</Divider>
      <Form className="info" form={infoForm}>
        <Form.Item label="Check in Time" name="date" rules={[
          {required: true}
        ]}>
          <DatePicker.RangePicker onChange={refreshPrice}/>
        </Form.Item>
        <Form.Item hidden name="hotel_id">
          <Input/>
        </Form.Item>
        <Form.Item noStyle>
          <div className="line">
            <Form.Item label="surname" name="surname" rules={[
              {required: true}
            ]}><Input/></Form.Item>
            <Form.Item label="name" name="name" rules={[
              {required: true}
            ]}><Input/></Form.Item>
          </div>
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[
          {required: true}
        ]}>
          <Input placeholder="For example, +86 11588776655"/>
        </Form.Item>
        <Form.Item label="Phone number" name="phone_number" rules={[
          {required: true}
        ]}>
          <Input placeholder="For example, +86 11588776655"/>
        </Form.Item>
      </Form>
      <Divider style={{marginTop: 0,marginBottom: 25}} orientation="left">Price</Divider>
      <div className="price">
        <p>
          <span>Daily price:</span>
          <span>${props.hotel?.price} + ${props.hotel?.real_service_charge}(serviceCharge)</span>
        </p>
        <p>
          <span>Total price:</span>
          <span>${price}</span>
        </p>
      </div>
    </div>
  )
}

export default List