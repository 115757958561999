import "./index.scss"
import {Button, Table} from "antd";
import MakeWork, {MakeWorkRef} from "@/Components/MakeWork";
import {useRef} from "react";
import {useRequest} from "@/Request";
import {getWorks} from "@/Request/api";
import WorkValue, {WorkValueRef} from "@/Components/WorkValue";

function Work() {
  const ref = useRef<MakeWorkRef>()
  const {data, loading, action} = useRequest(getWorks, {
    auto: true
  })
  const detailRef = useRef<WorkValueRef>()
  return (
    <div id="work">
      <Button className="action" onClick={() => {
        ref.current?.open()
      }}>MakeWorkOrder</Button>
      <MakeWork onRef={ref}/>
      <Table style={{width: "100%"}} scroll={{
        x: true
      }} rowKey="id" pagination={false} bordered dataSource={data} columns={[
        {
          title: "workOrderID",
          dataIndex: "id"
        },
        {
          title: "title",
          dataIndex: "title"
        },
        {
          title: "status",
          dataIndex: "status",
          render(status: string) {
            return ["Closed", "🔔Replied", "⏳Waiting for reply"][Number(status)]
          }
        },
        {
          title: "action",
          key: "action",
          render(_, record) {
            return (
              <Button type="primary" onClick={() => {
                detailRef.current?.open(record.id)
              }}>View details</Button>
            )
          }
        }
      ]}/>
      <WorkValue refresh={action} onRef={detailRef}/>
    </div>
  )
}

export default Work