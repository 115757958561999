import "./index.scss"
import Header from "@/Components/Header";
import Banner from "@/Components/Banner";
import {Link, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import exit from "./img/exit.png"
import list from "./img/list.png"
import work from "./img/work.png"
import Store from "@/Store";
import OrderList from "@/Pages/User/OrderList";
import Work from "@/Pages/User/Work";
import {useRequest} from "@/Request";
import {getUserInfo, postUserInfo} from "@/Request/api";
import {Button, Form, Input, message, Modal, Spin} from "antd";
import avatar from "@/Pages/Login/avatar.jpg"
import {useState} from "react";

const User = function () {
  const navigate = useNavigate()
  const location = useLocation()
  const isUserPage = location.pathname === "/user"
  const {data, loading, action: dispatchGetUserInfo} = useRequest(getUserInfo, {
    auto: true
  })
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const {action: dispatchPostUser, loading: postUserLoading} = useRequest(postUserInfo, {
    onSuccess(res) {
      message.success(res)
      dispatchGetUserInfo()
      setVisible(false)
    }
  })
  return (
    <div id="user">
      <Header/>
      <Banner title="User center"/>
      <div className="z-width">
        <Spin spinning={loading}>
          <div className="flex">
            <div className="user-info">
              <img src={avatar} alt=""/>
              <div className="name">{data?.name}</div>
              <div className="balance">balance: <span>${data?.wallet_num}</span></div>
              <Button size="small" type="primary" onClick={() => {
                form.resetFields()
                setVisible(true)
                form.setFieldsValue({
                  name: data?.name ?? ""
                })
              }}>Modify user data</Button>
            </div>
            <div className="right">
              {isUserPage && (
                <div className="links">
                  <Link to="order_list" className="item">
                    <img className="icon" src={list} alt=""/>
                    Order list
                  </Link>
                  <Link to="work_order" className="item">
                    <img className="icon" src={work} alt=""/>
                    Work order
                  </Link>
                  <div className="item" onClick={() => {
                    Store.setToken("")
                    navigate("/")
                  }}>
                    <img className="icon" src={exit} alt=""/>
                    Log out
                  </div>
                </div>
              )}
              <Routes>
                <Route path="order_list" element={<OrderList/>}/>
                <Route path="work_order" element={<Work/>}/>
              </Routes>
            </div>
          </div>
        </Spin>
      </div>
      <Modal confirmLoading={postUserLoading} title="Modify user data" visible={visible} onCancel={() => setVisible(false)} onOk={form.submit}>
        <Form form={form} labelCol={{span: 5}} onFinish={values => dispatchPostUser(values)}>
          <Form.Item label="Username" name="name" rules={[
            {required: true}
          ]}>
            <Input/>
          </Form.Item>
          <Form.Item label="Password" name="password">
            <Input placeholder="Please leave blank if not modified"/>
          </Form.Item>
          <Form.Item label="RepeatPwd" name="repeat_pwd" rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if ((!value && !getFieldValue("password")) || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}>
            <Input/>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
export default User