import {useEffect, useRef, useState} from "react";
import axios, {AxiosRequestConfig} from "axios"
import {message} from "antd";
import store from "@/Store";

const baseURL:string = "/api"

type ResponseData<T> = {
  code: number,
  data: T,
  message: string
}


export function useLatest<T>(value: T) {
  const ref = useRef<T>(value)
  ref.current = value
  return ref
}

export const request = {
  axios: axios.create({
    baseURL
  }),
  request<T>(params?: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      request.axios.request<ResponseData<T>>({
        ...params
      }).then((res) => {
        resolve(res.data.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  get<T>(url: string, params?: any): Promise<T> {
    return request.request<T>({
      method: "get",
      url,
      params
    })
  },
  post<T>(url: string, data?: any): Promise<T> {
    return request.request<T>({
      method: "post",
      url,
      data
    })
  },
  put<T>(url: string, data?: any): Promise<T> {
    return request.request<T>({
      method: "put",
      url,
      data
    })
  },
  delete<T>(url: string, data?: any): Promise<T> {
    return request.request<T>({
      method: "delete",
      url,
      data
    })
  }
}

request.axios.interceptors.request.use((config) => {
  if (store.token && config?.headers) {
    config.headers["token"] = String(store.token)
  }
  return config
})

request.axios.interceptors.response.use((res) => {
  if (res.data.hasOwnProperty("code") && res.data.hasOwnProperty("message") && res.data.hasOwnProperty("data")) {
    if (res.data.code === 200) {
      return Promise.resolve(res)
    } else if (res.data.code === 400) {
      window.location.hash="/login"
      store.setToken("")
    }
    message.error(res.data.message)
    return Promise.reject(res.data.message)
  }
}, (err) => {
  message.error(err.message ?? "请求服务器失败")
})

type Options<TData, TArgs, > = {
  auto?: boolean
  onSuccess?: (res: TData) => void
  onError?: (err: any) => void
  onFinally?: () => void
  init?: TData
  defaultParams?: TArgs
}

export function useRequest<TData, TArgs extends any[]>(promise: (...args: TArgs) => Promise<TData>, options: Options<TData, TArgs> = {}) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<TData | undefined>(options?.init ?? undefined)

  if (!options.onError) {
    options.onError = err => {
      // console.error("useRequest:", err)
    }
  }

  function send(...args: TArgs): Promise<TData> {
    if (loading) {
      options?.onError?.(new Error("上一个请求还未返回结果 本次执行失败!"))
      return Promise.reject("上一个请求还未返回结果 本次执行失败!")
    }
    setLoading(true)
    return promise(...args).then((res: TData) => {
      options?.onSuccess?.(res)
      setData(res)
      return res
    }).catch((err) => {
      options?.onError?.(err)
      return err
    }).finally(() => {
      options?.onFinally?.()
      setLoading(false)
    })
  }

  useEffect(() => {
    if (options.auto === true) {
      const params = options.defaultParams || []
      // @ts-ignore
      send(...params)
    }
  }, [])
  return { loading, action: send, data }
}