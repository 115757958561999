import {request} from "@/Request/index";

type SendMailReq = {
  type: "register" | "login"
  email: string
}
export function postSendMail(req: SendMailReq) {
  return request.post<string>("send_mail", {...req})
}

type RegisterReq = {
  email: string
  code: string
  password: string
}
export function postRegister(req: RegisterReq) {
  return request.post<string>("register", {...req})
}

type LoginReq = {
  type: "code" | "password"
  email: string
  password?: string
  code?: string
}

export function postLogin(req: LoginReq) {
  return request.post<{
    message: string
    token: string
  }>("login", {...req})
}

export type UserInfo = {
  email: string
  name: string
  wallet_num: number
}

export function getUserInfo() {
  return request.get<UserInfo>("user/info")
}

export type BrandRaw = {
  name: string
  id: number
}

export function getBrand() {
  return request.get<BrandRaw[]>("brand")
}

export type HotelRaw = {
  id: number
  name: string
  price: number
  position: string
  p_image: string
  brand: BrandRaw
  real_service_charge: number
}

export function getHotels(obj: {[key: string]: any}) {
  return request.get<{
    count: number,
    list: HotelRaw[]
  }>("hotels", {...obj})
}

export function getSetting() {
  return request.get<{[key: string]: any}>("setting")
}

export function postOrder(values: any) {
  return request.post<number>("user/order", {...values})
}

export type OrderRaw = {
  id: number
  user: UserInfo
  price: number
  hotel: HotelRaw
  start_date: string
  end_date: string
  status: number
}

export function getOrder(id: number) {
  return request.get<OrderRaw>("user/order", {id})
}

type PostPayRes = {
  type: "success" | "jump"
  message?: string
  url?: string
}

export function postPay(pay_type: string, order_id: number) {
  return request.post<PostPayRes>("user/pay", {pay_type, order_id})
}

type GetOrderStatusRes = {
  type: "error" | "success"
  status: 0 | 1
  message: string
}
export function getOrderStatus(id: number) {
  return request.get<GetOrderStatusRes>("user/order_status", {id})
}

export function getOrderList() {
  return request.get<OrderRaw[]>("user/order_list")
}

type PostWorkReq = {
  title: string
  value: string
}
export function postWork(req: PostWorkReq) {
  return request.post<string>("user/work", {...req})
}

export type WorkRaw = {
  id: number
  title: string
  status: number
}
export function getWorks() {
  return request.get<WorkRaw[]>("user/works")
}

export type WorkValueRaw = {
  id: number
  is_user: number
  value: string
}
export function getWorkValue(id: number) {
  return request.get<{list: WorkValueRaw[], close: boolean}>("user/work_value", {id})
}

export function postWorkValue(id: number, value: string) {
  return request.post<string>("user/work_value", {id, value})
}

export function postCloseWork(id: number) {
  return request.post<string>("user/close_work", {id})
}

export function postUserInfo(obj: any) {
  return request.post<string>("user/info", {...obj})
}

export function postContact(values: any) {
  return request.post<string>("contact", {...values})
}

export function getIndexData() {
  return request.post<{
    hotel: HotelRaw[]
  }>("index_data")
}

export function getSearchList(key: string) {
  return request.get<{
    list: HotelRaw[],
    keyword: string
  }>("search_list", {keyword: key})
}