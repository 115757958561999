import {ReactNode} from "react";
import img from "./image.jpg"
import "./index.scss"

type Props = {
  children?: ReactNode
  image?: string
  height?: string
  className?: string
  title?: string
  subtitle?: string
  opacity?: boolean
}

const Banner = function (props: Props) {
  const opacity = props.opacity ?? true
  return (
    <div className={"component-banner " + (props.className ?? "")} style={{
      backgroundImage: `url(${props.image ?? img})`
    }}>
      {
        opacity ? (
          <div className="zz"/>
        ): null
      }
      {
        props.title ? (
          <div className="title-box">
            <div className="title">{props.title}</div>
            {props.subtitle && (
              <div className="subtitle">{props.subtitle}</div>
            )}
          </div>
        ) : props.children ?? ""
      }
    </div>
  )
}

export default Banner