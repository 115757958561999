import "./index.scss"
import {observer} from "mobx-react-lite";
import Store from "@/Store";

const Footer = function () {

  return (
    <div id="footer">
      <img src={`/storage/${Store.setting.logo}`} alt=""/>
      <div dangerouslySetInnerHTML={{__html: Store.setting.common_footer_text}}></div>
    </div>
  )
}

export default observer(Footer)