import "./index.scss"
import {Link, NavLink, useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import Store from "@/Store";
import {useRequest} from "@/Request";
import {getUserInfo} from "@/Request/api";
import moment from "moment";
import $ from "jquery"

type Props = {
  white?: boolean
  position?: boolean
}

const Header = (props: Props) => {
  let position = props.position ?? true
  let white = props.white ?? true
  const {data: user, action} = useRequest(getUserInfo)
  const navigate = useNavigate()
  useEffect(() => {
    if (Store.token) {
      action()
    }
    $("#header .menu-icon").on("click", function () {
      $("#header .mask, #header .center .right").toggleClass("active")
    })
    $("#header .mask").on("click", function () {
      $("#header .mask, #header .center .right").toggleClass("active")
    })
  }, [])
  return (
    <div id="header" className={(position ? "position" : "") + " " + (white ? "white" : "")}>
      <div className="center width">
        <a className="logo" onClick={() => navigate("/")}>
          <img src={`/storage/${Store.setting.logo}`} alt=""/>
        </a>
        <div className="mask"></div>
        <div className="menu-icon"></div>
        <div className="right">
          <div className="menus">
            <NavLink to="/">Home</NavLink>
            <NavLink to="/list" state={{
              value: "",
              date: [
                moment().format(),
                moment().add(1, "d").format()
              ]
            }}>Hotels</NavLink>
            <NavLink to="/contact">Contact</NavLink>
          </div>
          <div className="users">
            {
              Store.token ? (
                user && (
                  <Link to="/user" className="info">
                    User center
                  </Link>
                )
              ) : (
                <div className="login">
                  <Link to="/login" className="login-btn">
                    Login
                  </Link>
                  <Link to="/register" className="register-btn">
                    Register
                  </Link>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Header)