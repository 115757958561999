import {makeAutoObservable, observe} from "mobx"
import {getSetting} from "@/Request/api";

const Store = makeAutoObservable({
  token: "",
  setting: {
    name: "",
    logo: "",
    contact_position: "",
    contact_phone: "",
    contact_email: "",
    contact_faq_title: "",
    contact_faq_text: "",
    contact_faq_url: "",
    contact_faq_btn: "",
    contact_b_title: "",
    contact_b_text: "",
    common_footer_text: ""
  },
  setSetting(setting: {[key: string]: any}) {
    for (const settingKey in setting) {
      // @ts-ignore
      this.setting[settingKey] = setting[settingKey]
    }
  },
  setToken(token: string) {
    this.token = token
  }
})

observe(Store, "token", function (change) {
  localStorage.setItem("hotel_token", change.newValue as string)
})

Store.setToken(localStorage.getItem("hotel_token") ?? "")

getSetting().then((res) => {
  Store.setSetting(res)
  window.document.title = res.name
})


export default Store