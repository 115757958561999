import "./index.scss"
import {ReactNode} from "react";

type Props = {
  action?: ReactNode
  text?: ReactNode
  data: {
    image: string
    title: string
    position: string
    brand: string
  }
}

const Hotel = function (props: Props) {
  return (
    <div className="component-hotel">
      <div className="f-l">
        <img className="left" src={props.data.image} alt=""/>
        <div className="center">
          <div className="title">{props.data.title}</div>
          <div className="position">{props.data.position}</div>
          <div className="brand">{props.data.brand}</div>
        </div>
      </div>
      <div className="right">
        <div className="text">
          {props.text}
        </div>
        <div className="action">
          {props.action}
        </div>
      </div>
    </div>
  )
}
export default Hotel