import {Form, Input, InputRef, message, Modal, Spin} from "antd";
import {Ref, useImperativeHandle, useRef, useState} from "react";
import {useRequest} from "@/Request";
import {postWork} from "@/Request/api";

export type MakeWorkRef = {
  open(title?: string, value?: string) :void
} | undefined

type Props = {
  onRef: Ref<MakeWorkRef>
}

export default function MakeWork(props: Props) {
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const inputRef = useRef<InputRef>(null);
  const {action, loading} = useRequest(() => {
    return postWork(form.getFieldsValue())
  }, {
    onSuccess(msg) {
      message.success(msg)
      setVisible(false)
    }
  })
  useImperativeHandle(props.onRef, () => ({
    open(title, value) {
      setVisible(true)
      let values: any = {
        title: "",
        value: ""
      }
      if (title) {
        values.title = "[" + title + "]"
      }
      if (value) {
        values.value = value
      }
      setTimeout(() => inputRef.current?.focus({
        cursor: "end"
      }), 300)
      form.setFieldsValue(values)
    }
  }))
  return (
    <Modal visible={visible} title="make work order" onCancel={() => setVisible(false)} onOk={action}>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <Form.Item label="title" name="title" rules={[
            {required: true}
          ]}>
            <Input placeholder="please enter title"/>
          </Form.Item>
          <Form.Item label="value" name="value" rules={[
            {required: true}
          ]}>
            <Input.TextArea rows={10} ref={inputRef}/>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}