import "./index.scss"
import React, {ReactElement, ReactNode, Ref, useImperativeHandle, useRef, useState} from "react";
import {Button, message, Modal, Spin} from "antd";
import paypal from "./paypal.png"
import wallet from "./wallet.png"
import {useRequest} from "@/Request";
import {getOrder, getOrderStatus, postPay} from "@/Request/api";
import {useInterval} from "ahooks"
import success from "./success.png"
import error from "./error.png"

export type PayRef = {
  open(order_id: number): void
}

type Props = {
  onRef: Ref<PayRef | undefined>
  close():void
}

function Pay(props: Props) {
  const [orderId, setOrderId] = useState<number>(0)
  const [price, setPrice] = useState(0)
  const [walletNum, setWalletNum] = useState(0)
  const [payType, setPayType] = useState("")
  useImperativeHandle(props.onRef, () => ({
    open(id) {
      setModalType("pay_type")
      setVisible(true)
      setOrderId(id)
      dispatchGetOrder(id)
    }
  }))

  const [visible, setVisible] = useState(false)
  const [modalType, setModalType] = useState<"pay_type"|"load"|"result">("pay_type")
  const [time, setTime] = useState<number|undefined>(undefined)
  const [result, setResult] = useState<{
    type: "success" | "error"
    message: string
  }>({
    type: "success",
    message: ""
  })

  const {loading, action: dispatchGetOrder} = useRequest(getOrder, {
    onSuccess(res) {
      setPrice(res.price)
      setWalletNum(res.user.wallet_num)
    }
  })

  const {loading: payLoading, action:dispatchPay} = useRequest(postPay, {
    onSuccess(res) {
      if (res.type === "success") {
        message.success(res.message)
        setModalType("result")
        setResult({
          type: "success",
          message: res.message ?? ""
        })
      } else if (res.type === "jump") {
        setModalType("load")
        window.open(res.url)
        setTime(1000)
      }
    }
  })

  const {loading: getOrderStatusLoading, action: dispatchGetOrderStatus} = useRequest(getOrderStatus, {
    onSuccess(res) {
      if (res.status !== 0) {
        setTime(undefined)
        setModalType("result")
        setResult({
          type: res.type,
          message: res.message
        })
      }
    },
    onError() {
      cleanLoad()
    }
  })

  useInterval(() => {
    dispatchGetOrderStatus(orderId)
  }, time)

  function payTypeSubmitEvent() {
    if (payType === "wallet") {
      if (price > walletNum) {
        message.warning("Insufficient wallet balance！")
        return
      }
    }
    dispatchPay(payType, orderId)
  }

  function cleanLoad() {
    setTime(undefined)
    setModalType("pay_type")
  }


  return (
    <>
      <Modal
        maskClosable={false}
        className="component-pay"
        title={
          modalType === "pay_type" ? "支付类型" : (
            modalType === "load" ? "等待支付" : "支付结果"
          )
        }
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={false}
        closable={false}
      >
        <Spin spinning={loading || payLoading}>
          { modalType === "pay_type" ? (
            <div className="pay_type">
              <p>Need to pay: <span>${price}</span>，Please select the payment method:</p>
              <div className="pay_list">
                <div className={`pay_item ${payType === "paypal" ? "active" : ""}`} onClick={() => setPayType("paypal")}>
                  <img src={paypal} alt=""/>
                  <div className="text">
                    <p>PayPal</p>
                  </div>
                </div>
                <div className={`pay_item wallet ${payType === "wallet" ? "active" : ""}`} onClick={() => setPayType("wallet")}>
                  <img src={wallet} alt=""/>
                  <div className="text">
                    <p>Account balance</p>
                    <p>(Total: ${walletNum})</p>
                  </div>
                </div>
              </div>
              <div className="action">
                <Button type="primary" onClick={payTypeSubmitEvent}>OK, Pay Now</Button>
                <Button onClick={() => {
                  setVisible(false)
                }}>go black</Button>
              </div>
            </div>
          ) : modalType === "load" ? (
              <div className="pay_load">
                <Spin spinning={getOrderStatusLoading}>
                  <p>Please complete the payment in the new tab</p>
                </Spin>
                <Button danger onClick={cleanLoad}>Cancel payment</Button>
              </div>
            ) : modalType === "result" ? (
              <div className="pay_result">
                {
                  result.type === "error" ? (
                    <img src={error} alt="" className="icon"/>
                  ) : (
                    <img src={success} alt="" className="icon"/>
                  )
                }
                <p>{result.message}</p>
                <div className="action">
                  {
                    result.type === "error" ? (
                      <Button type="primary" block>Go to user home</Button>
                    ) : (
                      <Button danger type="primary" block onClick={() => {
                        setVisible(false)
                        props.close()
                      }}>Close</Button>
                    )
                  }
                </div>
              </div>
            ) : null
          }
        </Spin>
      </Modal>
    </>
  )
}
export default Pay