import icon1 from "./icons/11.png";
import icon2 from "./icons/12.png";
import icon3 from "./icons/13.png";
import icon4 from "./icons/14.png";

type Props = {
  className?: string
}

const Service = function (props: Props) {
  return (
    <div className={"service " + (props.className ?? "")}>
      <div className="item">
        <img src={icon1}/>
        <div className="text">
          <div className="title">Spend less</div>
          <div className="subtitle">Competitive ultra-low house price</div>
        </div>
      </div>
      <div className="item">
        <img src={icon2}/>
        <div className="text">
          <div className="title">Better to live</div>
          <div className="subtitle">Select world famous hotels</div>
        </div>
      </div>
      <div className="item">
        <img src={icon3}/>
        <div className="text">
          <div className="title">More flexible</div>
          <div className="subtitle">Free cancellation without prepayment</div>
        </div>
      </div>
      <div className="item">
        <img src={icon4}/>
        <div className="text">
          <div className="title">Interested</div>
          <div className="subtitle">Official website channel calculation effective accommodation</div>
        </div>
      </div>
    </div>
  )
}

export default Service