import {Button, Form, Input, InputRef, message, Modal, Spin} from "antd";
import "./index.scss"
import {Ref, useImperativeHandle, useRef, useState} from "react";
import {useRequest} from "@/Request";
import {getWorkValue, postCloseWork, postWorkValue} from "@/Request/api";

export type WorkValueRef = {
  open(id: number): void
}

type Props = {
  onRef: Ref<WorkValueRef| undefined>
  refresh(): void
}

export default function WorkValue(props: Props) {
  const [visible, setVisible] = useState(false)
  const [appendVisible, setAppendVisible] = useState(false)
  const [form] = Form.useForm()
  const inputRef = useRef<InputRef>(null)
  const [id, setId] = useState(0)
  const {action, data, loading} = useRequest(getWorkValue)
  const {action: dispatchAppendWorkValue, loading: appendWorkLoading} = useRequest((values: any) => {
    return postWorkValue(id, values.value)
  }, {
    onSuccess(res) {
      message.success(res)
      setAppendVisible(false)
      action(id)
    }
  })
  const {action: dispatchCloseWork, loading: closeWorkLoading} = useRequest(() => {
    return postCloseWork(id)
  }, {
    onSuccess(res) {
      message.success(res)
      setVisible(false)
      props.refresh()
    }
  })
  useImperativeHandle(props.onRef, () => ({
    open(id) {
      setVisible(true)
      setId(id)
      action(id)
    }
  }))
  return (
    <>
      <Modal
        confirmLoading={appendWorkLoading}
        maskClosable={false}
        visible={appendVisible}
        title="Append message"
        onCancel={() => setAppendVisible(false)}
        onOk={() => {
          form.submit()
        }}
      >
        <Form form={form} layout="vertical" onFinish={(values) => {
          dispatchAppendWorkValue(values)
        }}>
          <Form.Item label="Message value:" name="value" rules={[
            {required: true}
          ]}>
            <Input.TextArea ref={inputRef} rows={5} placeholder="please enter message..."></Input.TextArea>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        onCancel={() => {
          setVisible(false)
        }}
        maskClosable={false}
        visible={visible}
        title="Work order details"
        className="values-modal"
        footer={(
          <>
            <Button disabled={data?.close} loading={loading} type="primary" danger onClick={() => {
              Modal.confirm({
                title: "Are you sure you want to end the work order",
                content: "The message cannot be submitted after the work order is completed.",
                onOk() {
                  dispatchCloseWork()
                }
              })
            }}>End work order</Button>
            <Button disabled={data?.close} loading={loading} type="primary" onClick={() => {
              form.resetFields()
              setTimeout(() => {
                inputRef.current?.focus()
              }, 150)
              setAppendVisible(true)
            }}>Append message</Button>
          </>
        )}
      >
        <Spin spinning={loading}>
          <div className="values-box">
            {data?.list.map(item => {
              return (
                <div key={item.id} className={`item ${item.is_user ? "user" : ""}`}>
                  {item.value}
                </div>
              )
            })}
          </div>
        </Spin>
      </Modal>
    </>
  )
}