import "./index.scss"
import Header from "@/Components/Header";
import Banner from "@/Components/Banner";
import image from "@/Pages/Home/image.jpg"
import avatar from "./avatar.jpg"
import {ReactNode, useState} from "react";
import {Button, Form, Input, message, Spin} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {useRequest} from "@/Request";
import {postLogin, postRegister, postSendMail} from "@/Request/api";
import Store from "@/Store";
import {observer} from "mobx-react-lite"

type Props = {
  title: string
  children: ReactNode
}
const Template = function (props: Props) {
  return (
    <div id="login">
      <Header/>
      <Banner image={image} className="banner">
        <div className="login-box">
          <img className="avatar" src={avatar} alt=""/>
          <div className="title">{props.title}</div>
          <div className="children">
            {props.children}
          </div>
        </div>
      </Banner>
    </div>
  )
}

export const Login = observer(() => {
  const navigate = useNavigate()
  const {action: dispatchLogin, loading} = useRequest(postLogin, {
    onSuccess(res) {
      message.success(res.message)
      Store.setToken(res.token)
      navigate("/")
    }
  })
  return (
    <Template title="Login">
      <Form onFinish={dispatchLogin} layout="vertical">
        <Form.Item name="type" hidden={true} initialValue="password">
          <input type="text"/>
        </Form.Item>
        <Form.Item label="email" name="email" rules={[
          {required: true},
          {type: "email"}
        ]}>
          <Input/>
        </Form.Item>
        <Form.Item label="password" name="password" rules={[
          {required: true}
        ]}>
          <Input.Password/>
        </Form.Item>
        <Button type="primary" htmlType="submit" block loading={loading}>Login</Button>
      </Form>
      <div className="line">
        <Link to="/register">register</Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Link to="/email_login">Forget password?</Link>
      </div>
    </Template>
  )
})

export const Register = function () {
  const [form] = Form.useForm()
  const [time, setTime] = useState(0)
  const navigate = useNavigate()
  const {action: dispatchSendMail, loading: sendMailLoading} = useRequest(postSendMail, {
    onSuccess(res) {
      message.success(res)
    }
  })
  const {action: dispathRegister, loading: registerLoading} = useRequest(postRegister, {
    onSuccess(res) {
      message.success(res)
      navigate("/login")
    }
  })
  return (
    <Template title="Register">
      <Spin spinning={sendMailLoading || registerLoading}>
        <Form className="reg-form" form={form} onFinish={values => {
          dispathRegister(values)
        }}>
          <Form.Item label="email" name="email" rules={[
            {type: "email"}, {required: true}
          ]}>
            <Input/>
          </Form.Item>
          <Form.Item noStyle>
            <div className="form-line">
              <Form.Item label="captcha" name="code" required>
                <Input maxLength={4}/>
              </Form.Item>
              <Button onClick={() => {
                form.validateFields(["email"]).then(values => {
                  if (time === 0 || new Date().getTime() - time > 60000) {
                    setTime(new Date().getTime())
                    dispatchSendMail({
                      type: "register",
                      email: values["email"]
                    })
                  } else {
                    message.warning("Please wait 60 seconds")
                  }
                })
              }}>Send</Button>
            </div>
          </Form.Item>
          <Form.Item label="password" name="password" rules={[
            {required: true}
          ]}>
            <Input.Password maxLength={32}/>
          </Form.Item>
          <Form.Item label="repeat" name="repeat" rules={[
            {required: true},
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            })
          ]}>
            <Input.Password placeholder="Enter the password again"/>
          </Form.Item>
          <Button type="primary" block onClick={form.submit}>Register</Button>
        </Form>
        <div className="line">
          Existing account？ <Link to="/login">Login</Link>
        </div>
      </Spin>
    </Template>
  )
}

export const EmailLogin = () => {
  const [form] = Form.useForm()
  const [time, setTime] = useState(0)
  const navigate = useNavigate()
  const {action: dispatchSendMail, loading: sendMailLoading} = useRequest(postSendMail, {
    onSuccess(res) {
      message.success(res)
    }
  })
  const {action: dispatchLogin, loading} = useRequest(postLogin, {
    onSuccess(res) {
      message.success(res.message)
      Store.setToken(res.token)
      navigate("/")
    }
  })
  return (
    <Template title="Log in using email">
      <Spin spinning={sendMailLoading || loading}>
        <Form className="reg-form" form={form} onFinish={dispatchLogin}>
          <Form.Item name="type" hidden={true} initialValue="code">
            <input type="text"/>
          </Form.Item>
          <Form.Item noStyle>
            <Form.Item label="email" name="email" rules={[
              {type: "email"}, {required: true}
            ]}>
              <Input/>
            </Form.Item>
            <div className="form-line">
              <Form.Item label="code" name="code" required>
                <Input maxLength={4}/>
              </Form.Item>
              <Button onClick={() => {
                form.validateFields(["email"]).then(values => {
                  if (time === 0 || new Date().getTime() - time > 60000) {
                    setTime(new Date().getTime())
                    dispatchSendMail({
                      type: "login",
                      email: values["email"]
                    })
                  } else {
                    message.warning("Please wait 60 seconds")
                  }
                })
              }}>Send</Button>
            </div>
          </Form.Item>
          <Button type="primary" block onClick={form.submit}>Login</Button>
        </Form>
        <div className="line">
          <Link to="/login">Login with password</Link>
        </div>
      </Spin>
    </Template>
  )
}