import {useRequest} from "@/Request";
import {getOrderList} from "@/Request/api";
import {Spin} from "antd";
import "./index.scss"

const OrderList = function () {
  const {data, loading} = useRequest(getOrderList, {
    auto: true
  })
  return (
    <div id="order-list">
      <Spin spinning={loading}>
        <h1>Order List</h1>
        <p>Show only successfully paid orders</p>
        <div className="list">
          {data?.map((item) => {
            return (
              <div className="item">
                <img src={item.hotel.p_image} className="hotel-img" alt=""/>
                <div className="hotel-info">
                  <div className="name">{item.hotel.name}</div>
                  <div className="position">{item.hotel.position}</div>
                  <div className="brand">{item.hotel.brand.name}</div>
                </div>
                <div className="right">
                  <div className="date">
                    {item.start_date}~{item.end_date}
                  </div>
                  <div className="price">${item.price}</div>
                  <div className={"status status-" + (item.status)}>{["Pending...", "OK"][item.status]}</div>
                </div>
              </div>
            )
          })}
        </div>
      </Spin>
    </div>
  )
}
export default OrderList